:local .container {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  overflow: auto;
  width: 100%;
}
:local .page {
  margin: 0 19.5px;
  max-height: 100%;
  max-width: 715px;
  padding: 19.5px 0;
  width: 100%;
}
:local .page .title {
  font-size: 21px;
}
:local .header {
  padding: 0 13px;
}
:local .header,
:local .header .actions {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
:local .header .actions {
  margin-left: -10.75px;
  margin-bottom: 19.5px;
  padding-left: 2px;
}
:local .content {
  padding: 13px;
}
:local .pageControls {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 19.5px;
  padding: 0 3px 0 2px;
}
:local .pageControls .left {
  flex: 1;
}
:local .pageControls .right {
  padding-right: 0;
}
