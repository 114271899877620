:local .container {
  border-radius: 4px;
  border: none;
  cursor: pointer;
  display: inline-block;
  font-family: "Lato", "Helvetica Neue", "Helvetica", sans-serif;
  font-size: 15px;
  font-weight: 600;
  line-height: 23px;
  margin: 0;
  outline: none;
  padding: 0.5em 1em;
  text-align: center;
  text-decoration: none;
  transition: 250ms opacity linear;
}
:local .container:active {
  opacity: 0.95;
}
:local .colorWhite,
:local .colorWhite:active,
:local .colorWhite:hover,
:local .colorWhite:visited {
  background-color: #fff;
  color: #171414;
}
:local .colorBlack,
:local .colorBlack:active,
:local .colorBlack:hover,
:local .colorBlack:visited {
  background-color: #171414;
  color: #fff;
}
