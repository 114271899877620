:local .container {
  background-color: #171414;
  box-shadow: 1px 0 3.25px rgba(0, 0, 0, 0.06), 1px 0 rgba(4, 4, 4, 0.13);
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  padding: 13px;
  width: 286px;
}
:local .block {
  flex: 0 0 auto;
}
:local .block__margin {
  margin: 0 0 6.5px 0;
}
:local .block__padding {
  padding: 6.5px 0;
  margin: 6.5px 0 6.5px 0;
}
:local .spacer {
  flex: 1 1 auto;
}
:local .navMenu {
  width: 273px;
}
:local .title {
  display: flex;
  flex-direction: row;
}
:local .title .titleHelp {
  cursor: pointer;
  margin: 0 0 0 6.5px;
}
