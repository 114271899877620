:local .container {
  align-items: center;
  border-radius: 50px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 3px;
  transition: 250ms background-color ease-in-out;
}
:local .isActive {
  transition-duration: 50ms;
}
:local .colorWhite {
  background: rgba(255, 255, 255, 0);
}
:local .colorWhite .icon {
  fill: #fff;
}
:local .colorWhite.isActive {
  background: rgba(255, 255, 255, 0.1);
}
:local .colorBlack {
  background: rgba(0, 0, 0, 0);
}
:local .colorBlack .icon {
  fill: #171414;
}
:local .colorBlack.isActive {
  background: rgba(0, 0, 0, 0.1);
}
