:local .container {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  padding-right: 13px;
}
:local .container .text {
  margin: 0;
  font-weight: 600;
}
