* {
  margin: 0;
  padding: 0;
  user-select: none;
}
*,
*::after,
*::before {
  box-sizing: border-box;
}
html,
body {
  background-color: white;
  color: #171414;
  font-family: "Lato", "Helvetica Neue", "Helvetica", sans-serif;
  font-size: 15px;
  font-weight: 400;
  height: 100%;
  line-height: 23px;
  overflow: hidden;
  position: fixed;
  text-rendering: optimizeLegibility;
  width: 100%;
  z-index: 0;
}
::selection {
  background-color: transparent;
}
p {
  margin: 0 0 0.3em;
}
a,
a:active,
a:visited,
a:hover {
  color: #171414;
  text-decoration: none;
}
ul,
ol {
  margin: 0;
}
li {
  line-height: 23px;
  list-style: none;
  margin: 0;
}
@media (max-width: 600px) {
  li {
    display: inline-block;
    line-height: 1em;
    margin: 0 0.5em 0 0;
  }
}
ol > li {
  list-style: upper-roman inside;
}
article,
section {
  z-index: 0;
}
img {
  display: inline-block;
  vertical-align: middle;
  width: auto;
}
