:local .container {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  opacity: 0.9;
}
:local .container .action {
  flex: 0 0 auto;
}
:local .container .action:not(:last-child) {
  margin-right: 13px;
}
:local .container .spacer {
  flex: 1;
  height: 100%;
  width: 100%;
}
