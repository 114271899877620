:local .container {
  margin: 6.5px 0;
  padding: 6.5px 0;
}
:local .top {
  margin-top: 26px;
  border-top: 1px solid #3a3a3a;
}
:local .bottom {
  border-bottom: 1px solid #3a3a3a;
}
