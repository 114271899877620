:local .container {
  background-color: #f9f9f9;
  border-radius: 8px;
  margin-bottom: 19.5px;
  margin-left: -13px;
  margin-right: -13px;
  margin-top: 19.5px;
  padding: 13px;
}
:local .text {
  margin: 0;
}
:local .label {
  font-weight: 600;
  margin: 3.25px 0 6.5px 0;
}
:local .item {
  margin: 0 0 13px 0;
}
