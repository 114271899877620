:local .container {
  color: inherit;
  cursor: pointer;
  text-decoration: none;
  transition: 50ms border-color ease-in-out;
}
:local .container:hover {
  color: inherit;
  border-color: transparent;
}
:local .bold {
  font-weight: bold;
}
:local .colorWhite,
:local .colorWhite:active,
:local .colorWhite:visited {
  border-bottom: 1px solid #fff;
  color: #fff;
}
:local .colorBlack,
:local .colorBlack:active,
:local .colorBlack:visited {
  border-bottom: 1px solid #171414;
  color: #171414;
}
