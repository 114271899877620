:local .container {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}
:local .container .content {
  width: 300px;
  text-align: center;
}
