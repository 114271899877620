:local .overlay {
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
}
:local .container {
  background-color: rgba(255, 255, 255, 0.98);
  border-radius: 4px;
  left: 0;
  margin: 6.5px;
  padding: 6.5px;
  position: fixed;
  top: 0;
  z-index: 300;
}
:local .fadeEnter {
  opacity: 0;
  transform: translateY(-13px);
}
:local .fadeEnterActive {
  opacity: 1;
  transition: all 50ms ease-in-out;
  transform: translateY(0);
}
:local .fadeExit {
  opacity: 1;
  transform: translateY(0);
}
:local .fadeExitActive {
  opacity: 0;
  transition: all 75ms ease-in-out;
  transform: translateY(-13px);
}
:local .menuIcon {
  display: flex;
  align-items: flex-start;
}
:local .menuItems {
  padding: 13px 6.5px 26px 6.5px;
}
:local .menuItem {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 0 6.5px 0 0;
  margin: 0 0 6.5px 0;
  text-decoration: none;
  transition: 150ms background-color linear;
}
:local .menuItem:hover {
  background-color: rgba(0, 0, 0, 0.025);
  border-radius: 4px;
}
:local .menuItem .icon {
  padding-left: 0;
  margin-right: 6.5px;
}
:local .menuItem .text {
  margin: 0;
}
