:local .container {
  display: block;
  font-family: "Lato", "Helvetica Neue", "Helvetica", sans-serif;
  margin-bottom: 13px;
}
:local .colorWhite {
  color: #fff;
}
:local .colorBlack {
  color: #171414;
}
:local .colorGray {
  color: #868686;
}
:local .typeText {
  font-size: 15px;
  line-height: 23px;
}
:local .typeSmall {
  font-size: 13px;
  line-height: 23px;
}
:local .typeTitle {
  font-size: 21px;
  font-weight: 600;
  line-height: 1em;
}
:local .typeHeader {
  font-size: 30px;
  font-weight: 600;
  line-height: 1em;
}
