:local .sliderContainer {
  padding: 0 7px;
}
:local .slider {
  cursor: move;
  height: 24px;
  position: relative;
  width: 100%;
}
:local .railContainer,
:local .trackContainer {
  height: 24px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}
:local .railContainer .rail,
:local .trackContainer .rail,
:local .railContainer .track,
:local .trackContainer .track {
  background-color: #fbfbfd;
  border-radius: 4px;
  height: 4px;
  margin-top: 10px;
  opacity: 0.2;
  width: 100%;
}
:local .trackContainer {
  z-index: 2;
}
:local .handleContainer {
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 24px;
  justify-content: center;
  margin-left: -12px;
  position: absolute;
  top: 0;
  width: 24px;
  z-index: 3;
}
:local .handleContainer .handle {
  background-color: #fff;
  border-radius: 14px;
  border: 1px solid #d2d5de;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  height: 14px;
  width: 13px;
  z-index: 3;
}
