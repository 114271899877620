:local .iconSymbol {
  background-color: #ececec;
  border-radius: 4px;
  cursor: default;
  display: inline-block;
  height: 30px;
  margin: 3.75px;
  position: relative;
  top: -1px;
  vertical-align: middle;
}
:local .letterSymbol {
  background-color: #ececec;
  border-radius: 4px;
  display: inline-block;
  height: 30px;
  margin: 0 3.75px;
  padding-top: 3.25px;
  text-align: center;
  width: 30px;
}
