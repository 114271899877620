:local .container,
:local .pointVisualization,
:local .svg,
:local .transitionGroup {
  height: 100%;
  overflow: hidden;
  width: 100%;
}
:local .svg {
  cursor: crosshair;
}
:local .point .weightHalo {
  fill: rgba(183, 190, 218, 0.05);
  stroke: rgba(0, 0, 0, 0.05);
  stroke-width: 1;
}
:local .point .weight {
  fill: rgba(183, 190, 218, 0.1);
  stroke: rgba(0, 0, 0, 0.16);
  stroke-width: 13px;
}
:local .point .value {
  fill: rgba(0, 0, 0, 0.78);
  stroke: rgba(0, 0, 0, 0.5);
  stroke-width: 13px;
}
:local .point .marker {
  fill: rgba(0, 0, 0, 0.75);
}
:local .point .label,
:local .point .synthesisValue {
  font-family: "Lato", "Helvetica Neue", "Helvetica", sans-serif;
  font-size: 10px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.05);
}
:local .fadeEnter {
  opacity: 0;
  transform: translateY(13px);
}
:local .fadeEnterActive {
  opacity: 1;
  transition: all 300ms ease-in-out, transform 75ms ease-in;
  transform: translateY(0px);
}
