:local .container {
  margin: 6.5px 0;
}
:local .label,
:local .labelContainer {
  display: flex;
  flex-direction: row;
}
:local .label,
:local .right {
  flex: 1;
}
:local .right {
  text-align: right;
}
:local .help {
  cursor: pointer;
  margin: 0 0 0 6.5px;
}
