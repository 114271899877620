:local .container {
  display: flex;
  flex-direction: row;
  height: 100%;
  overflow: hidden;
  width: 100%;
}
:local .sidebar {
  height: 100%;
  width: 286px;
  flex: 0 0 auto;
}
:local .visualization {
  flex: 1;
  height: 100%;
}
