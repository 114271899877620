:local .overlay {
  background-color: rgba(255, 255, 255, 0.15);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
}
:local .wrapper {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 200;
}
:local .container {
  background-color: rgba(37, 34, 34, 0.95);
  border-radius: 12px;
  box-shadow: 0px 0 13px 6.5px rgba(0, 0, 0, 0.03), 0 0 0 6.5px rgba(4, 4, 4, 0.14);
  margin: 15px;
  padding: 13px;
  z-index: 300;
}
:local .container.error {
  box-shadow: 0px 0 13px 6.5px rgba(0, 0, 0, 0.03), 0 0 0 6.5px rgba(191, 95, 95, 0.33);
}
:local .header {
  display: flex;
}
:local .header .icon {
  margin-left: -2px;
  opacity: 0.2;
}
:local .title {
  margin: 8.66666667px 6.5px 6.5px 6.5px;
}
:local .title .text {
  margin: 0;
}
:local .content {
  opacity: 0.95;
  padding: 6.5px;
}
:local .actions {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: -3.25px;
  padding: 0 6.5px 8.66666667px 6.5px;
}
:local .actions .button {
  flex: 1;
  width: 100%;
}
:local .actions .button:not(:last-child) {
  margin-bottom: 6.5px;
}
:local .fadeEnter {
  opacity: 0;
  transform: translateY(6.5px);
}
:local .fadeEnterActive {
  opacity: 1;
  transition: all 55ms ease-in-out;
  transform: translateY(0);
}
:local .fadeExit {
  opacity: 1;
  transform: translateY(0);
}
:local .fadeExitActive {
  opacity: 0;
  transition: all 75ms ease-in-out;
  transform: translateY(13px);
}
